$(document).on('ready',() => {

  var state = {
    isScrolling: false
  };

  // PARALLAX SECTION SCROLL CONTROL
  var parallaxController = new ScrollMagic.Controller({globalSceneOptions: {triggerHook: "onEnter", duration: "200%"}});
  var fullWidthParallax = new ScrollMagic.Scene({
      triggerElement: '.parallax-banner',
      duration: "100%"
    })
    .setTween(".parallax-banner", 0.5, {backgroundPositionY: '75%', ease: Linear.easeNone})
    .addTo(parallaxController);

  // SECTION REVEALS
  window.sr = ScrollReveal();
  const revealOptions = {
    scale: 1 ,
    distance: '60px',
    viewFactor: 0.15,
    duration: 800,
    easing: 'ease-in-out'
  };
  sr.reveal('.reveal, .left', revealOptions);
  sr.reveal('.reveal-delay, .right', Object.assign(revealOptions, { delay: 200 }));
  
  // INTRO INLINE VIDEO
  var introBGVideo = new $.backgroundVideo($('#introVid'), {
    "videoid": "introBGVideo",
    "align": "centerX",
    "width": 1280,
    "height": 720,
    "path": "/videos/",
    "filename": "400_Loop_v6_960",
    "types": ["mp4", "webm", "ogv"],
    "poster": "/assets/images/home/homepage_videofallback.jpg",
    "loop": true
  });

  // NAVIGATION
  const $subNav = $('.sub-nav');
  const $mobileNav = $('#mobile-nav');
  const $scrollDown = $('#scroll-down');

  const hideMobileNav = () => {
    $mobileNav.addClass('closed');
    $mobileNav.removeClass('open');
    setTimeout(() => {
      $mobileNav.removeClass('closed');
    }, 600);
  };
  const showMobileNav = () => {
    $mobileNav.addClass('open');
  };
  const hideSubNav = () => {
    $subNav.removeClass('visible');
  };
  const showSubNav = () => {
    $subNav.addClass('visible');
  };

  function createNavWaypoints() {

    $('.wp').waypoint({
      handler: (direction) => {
        if (direction === 'down') {
          showSubNav();
        } else {
          hideSubNav();
        }
      },
      offset: '20px'
    });

    $('.wp-nav').waypoint({
      handler: function (direction) {
        var mobileOnly = $(this.element).attr('data-mobile-only');
        var onMobile = $(window).width() <= 600 ? true : false;
        if (mobileOnly && !onMobile) return false;
        if (direction === 'down') {
          var colorDown = $(this.element).attr('data-menu-down');
          $('.site-header').removeClass('menu-white').removeClass('menu-black')
            .addClass('menu-' + colorDown);
        } else {
          var colorUp = $(this.element).attr('data-menu-up');
          $('.site-header').removeClass('menu-white').removeClass('menu-black')
            .addClass('menu-' + colorUp);
        }
      },
      offset: '45px'
    });
  }

  createNavWaypoints();

  $(window).on('resize', function(){
    Waypoint.destroyAll();
    createNavWaypoints();
  });

  $mobileNav.find('.close-button').on('click', function () {
    hideMobileNav();
  });
  $subNav.find('.sub-nav__menu').on('click', function () {
    showMobileNav();
  });

  // SLIDESHOWS INIT
  $('.slideshow').slick({
    adaptiveHeight: true,
    touchMove: true
  });

  // CLICK HANDLERS //

  // HERO ARROW CLICK
  $scrollDown.on('click', () => {
    $('body, html').animate({ 
      scrollTop: $('.site-content').first().offset().top
    }, 500);
  });

  // GOOGLE MAP CONVIENENCE CLICKS
  $('.block--map').on('click', (e) => {
    const $map = $(e.currentTarget).find('iframe');
    $map.css('pointer-events', 'all');
  });

  // CLICK HANDLERS FLOOR PLAN
  $('.floor-plan__item').on('mouseenter', function(item){
    $(item.currentTarget).addClass('active');
  });

  $('.floor-plan__item').on('click', function(item){
    $('.floor-plan__item').removeClass('active');
    $(item.currentTarget).addClass('active');
    setTimeout(function(){
      $(item.currentTarget).removeClass('active');
    }, 3000);
  });

  $('.floor-plan__item').on('mouseout', function(item){
    $(item.currentTarget).removeClass('active');
  });

  $('.floor-plan-list__item').on('mouseover', function(item){
    if (state.isScrolling) { return; }
    var itemId = $(item.currentTarget).attr('data-item-id');
    $('.floor-plan__item').removeClass('active');
    $('.floor-plan__item[data-item-id="'+itemId+'"]').addClass('active');
  });

  $('.floor-plan-list__item').on('mouseout', function(item){
    if (state.isScrolling) { return; }
    var itemId = $(item.currentTarget).attr('data-item-id');
    $('.floor-plan__item[data-item-id="'+itemId+'"]').removeClass('active');
  });
});

// var scrollToThis = function($element, state) {
//   if ($(window).scrollTop() === $element.offset().top) { return ; }
//   state.isScrolling = true;
//   $('body, html').animate({ 
//     scrollTop: $element.offset().top
//   }, 500, function(){
//     state.isScrolling = false;
//   });
// }
